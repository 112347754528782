:root {
    --themeGreen:#5CE7C4
}
.homeNav ul {
    margin-left: auto;
    margin-right: auto;
}
.homeNav.bgColored {
    /* background-color: rgba(0,0,0,0.3); */
    /* transition: all 0.2s; */
    /* backdrop-filter: blur(12px); */
  }
.homeNav {
  /* backdrop-filter: blur(5px); */
}
.navbar-brand {
  max-width: 185px;
}
.landingPage .homeBtn{
    background: #5CE7C4;
    font-size: 18px;
    color: #222737;
    font-weight: 500;
    border: none;
    padding: 12px;
    min-width: 180px;
    border-radius: 30px;
    text-align: center;
    text-decoration: none;
    cursor: pointer;
}
.landingPage .homeBtn:hover {
    background: #05daa4;
}

.homeNav .nav-link:hover::before {
  content: '';
  width: 70%;
  position: absolute;
  height: 2px;
  transform: translate(-50%,0);
  bottom: 0;
  left: 50%;
  background-color: var(--textSecondColor);
  transition: all 0.3s;
}
.homeNav .nav-link {
  transition: all 0.3s;
  position: relative;
    color: #FFFFFF;
    font-size: 18px;
    padding-left: 16px !important;
    padding-right: 16px !important;
}
.landingPage .sec1 {
    /* background: url(../../images/sec1Bg.png); */
    background-size: cover;
    background-position: center;
    padding: 130px 0;
    min-height: 100vh;
    background-color: #251CC9;
    display: flex;
    align-items: center;
    overflow: hidden;
}
.landingPage .sec1 .imgDiv{
  transform: scale(1.85);
  position: relative;
  top: 0px;
  animation: MoveUpDown11 1.5s linear infinite;
}
@keyframes MoveUpDown11 {
  0%, 100% {
      top: 0px;
  }
  50% {
      top: 30px;
  }
}
.landingPage .sec1 .content{
  position: relative;
  z-index: 1;
}
.landingPage .sec1 h2{
    font-size: 67px;
    font-weight: bold;
    margin-bottom: 30px;
    color: #fff;
    margin-top: 20px;
}
.landingPage .sec2 {
    padding: 20px 0;
    background: var(--themeGreen);
}
.landingPage .sec2 .priceM{
    display: flex;
    align-items: center;
    /* justify-content: space-between; */
    /* flex-wrap: wrap; */
}
.landingPage .sec2 .price{
    font-size: 30px;
    font-weight: bold;
    color: #222737;
    padding: 0 15px;
}
.landingPage .sec3 {
    padding: 50px 0;
}
.landingPage .sec3Card {
    padding: 25px;
    text-align: center;
    background: #F8F8F8;
    border-radius: 40px;
    height: 100%;
}
.landingPage .sec3Card .imgDiv{
    height: 320px;
    position: relative;
    margin-bottom: 30px;
}
.landingPage .sec3Card .imgDiv img{
    max-height: 100%;
    max-width: 100%;
    position: absolute;
    top: 0;
    left: 50%;
    height: auto;
    transform: translate(-50%, 0);
}
.landingPage .sec3Card h2{
    color: #222737;
    font-size: 38px;
    font-weight: bold;
}
.landingPage .sec3Card .desc{
    color: #222737;
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 30px;
}
.landingPage .sec3Card .row2{
    text-align: left;
}
.landingPage .sec3Card .imgDiv2 {
    max-width: 390px;
    margin: auto;
}
.landingPage .sec4 {
    padding: 80px 0;
    background: #251CC9;
    text-align: center;
    color: white;
    margin: 40px 0;
}
.landingPage .sec4 h1{
    color: #fff;
    font-size: 46px;
    font-weight: bold;
    margin-bottom: 16px;
}
.landingPage .sec4 .descrip{
    color: #fff;
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 30px;
    padding: 0 30px;
}
.landingPage .sec4 .sec4Card{
    border-radius: 16px;
    padding: 25px;
    background: rgba(255,255,255,0.1);
    height: 100%;
}
.landingPage .sec4 .sec4Card h2{
    margin: 20px 0;
    font-weight: bold;
    font-size: 30px;
}
.landingPage .sec4 .sec4Card .desc{
    font-size: 15px;
    font-weight: 500;
}
.landingPage .joinComunitySec {
    background: #F8F8F8;
    border-radius: 17px;
    text-align: center;
    padding: 30px 50px;
    max-width: 900px;
    margin: auto;
}
.landingPage .joinComunitySec h2{
    font-size: 40px;
    font-weight: bold;
    color: #222737;
}
.landingPage .joinComunitySec .desc{
    font-size: 18px;
    color: #222737;
    margin: 16px 0;
}
 .faqSec {
  padding: 80px 0;
  max-width: 950px;
  margin: auto;
}
 .faqSec h1{
  font-size: 48px;
  font-weight: bold;
  margin-bottom: 30px;
  text-align: center;
}
 .accordion-button::after {
    background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='%23333' xmlns='http://www.w3.org/2000/svg'%3e%3cpath fill-rule='evenodd' d='M8 0a1 1 0 0 1 1 1v6h6a1 1 0 1 1 0 2H9v6a1 1 0 1 1-2 0V9H1a1 1 0 0 1 0-2h6V1a1 1 0 0 1 1-1z' clip-rule='evenodd'/%3e%3c/svg%3e");
    transform: scale(.7) !important;
  }
  .accordion-button:not(.collapsed)::after {
    background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='%23333' xmlns='http://www.w3.org/2000/svg'%3e%3cpath fill-rule='evenodd' d='M0 8a1 1 0 0 1 1-1h14a1 1 0 1 1 0 2H1a1 1 0 0 1-1-1z' clip-rule='evenodd'/%3e%3c/svg%3e");
}
 .faqSec .accordion-header button{
    font-weight: 600;
    font-size: 18px;
    color: #222737;
}
 .faqSec .card-body{
    padding: 0 20px;
}
 .faqSec .card-body p{
    color: #849090;
    font-size: 16px;
    margin-bottom: 0;
}
.accordion-button:not(.collapsed) {
    background: transparent;
}
.accordion-button:focus {
    box-shadow: none;
}
.accordion-item {
    border: 0;
}
.landingPage .videoSec {
  max-width: 1000px;
  text-align: center;
  padding: 50px 0;
  margin: auto;
}
.landingPage .videoSec h1{
  font-size: 48px;
  font-weight: bold;
  margin-bottom: 20px;
}
.landingPage .videoSec .desc{
  font-size: 18px;
  max-width: 850px;
  margin: auto;
  margin-bottom: 20px;
  font-weight: 500;
}
.landingPage .videoSec video{
  width: 100%;
  border-radius: 30px;
}


/* footer {
    background: #251CC9;
    border-top-right-radius: 40px;
    border-top-left-radius: 40px;
    padding: 50px 0;
  }
  footer .footerRow {
    display: flex;
  }
  footer .footerRow .col1 .logoDiv{
    max-width: 185px;
  }
  footer .footerRow .col1 p {
    color: #fff;
    font-size: 15px;
    margin: 20px 0;
  }
  footer .footerRow .col1 .mail:hover {
    color: #fff;
  }
  footer .footerRow .col1 .mail {
    color: #fff;
    font-size: 15px;
    margin-bottom: 20px;
    display: block;
    text-decoration: none;
  }
  footer .footerRow .col1 .socialMain {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 150px;
  }
  footer .footerRow .col1 .socialMain a:hover {
    transform: scale(1.1);
  }
  footer .footerRow .col2 {
    margin-left: auto;
  }
  footer .footerRow h6 {
    color: #fff;
    font-size: 15px;
    font-weight: bold;
  }
  footer .footerRow ul {
    list-style: none;
    padding: 0;
  }
  footer .footerRow ul li {
    padding: 5px 0;
  }
  footer .footerRow ul li a:hover {
    color: rgba(255,255,255,1);
  }
  footer .footerRow ul li a {
    color: rgba(255,255,255,0.8);
    font-size: 15px;
    text-decoration: none;
  }
  footer .footerRow .col3 {
    margin-left: 100px;
  } */
  .dib {
    display: inline-block;
  }
  .privacyModal .modal-dialog {
    max-width: 850px;
  }
  .privacyModal .corssBlackIco-modal {
    right: 12px;
    position: absolute;
    top: 10px;
    width: 30px;
    cursor: pointer;
  }
  .privacyModal h5 {
    padding-right: 30px;
  }

  .landingPage .founderSec h1{
    font-size: 40px;
    font-weight: bold;
    margin-bottom: 30px;
  }
  .landingPage .founderSec {
    padding: 60px 0;
  }
  .privacyModalBody {
    padding-top: 30px;
  }
  .loginGoogleMain {
    text-align: center;
  }
  .loginGoogleMain .signGoogleBtn:hover {
    background: #5CE7C4;
    transition: all 0.3s;
  }
  .loginGoogleMain .signGoogleBtn {
    transition: all 0.3s;
    border: 3px solid #5CE7C4;
    border-radius: 25px;
    margin: auto;
    margin-top: 15px;
    background: white;
    font-size: 18px;
    font-weight: 500;
    padding: 7px 15px;
    display: flex;
    align-items: center;
  }
  .loginGoogleMain .signGoogleBtn img {
    width: 30px;
    margin-right: 10px;
  }

  /* welcomePage */
  .welcomPage {
    padding: 50px 0;
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .welcomPage h3 {
    text-align: center;
  }
  .welcomPage h3 span{
    color: #05daa4;
  }
  
  @media(max-width:992px){
    .landingPage .sec1 .imgDiv {
      transform: scale(1.5);
      margin-top: 20px;
    }
  }
  @media(max-width:768px) and (min-width:576px){
      footer .footerRow .col3 {
          margin-left: 0;
    }
    footer .footerRow .col2 {
      margin-left: auto;
      margin-right: auto;
    }
  }  
  
  @media(max-width:576px){
    .landingPage .founderSec h1 {
      font-weight: bold;
    }
      footer .footerRow {
        flex-wrap: wrap;
      }
      footer .footerRow .col1 {
          width: 100%;
        margin-bottom: 30px;
      }
      footer .footerRow .col2 {
        margin-left: auto;
        margin-right: auto;
      }
      footer .footerRow .col3 {
        margin-left: auto;
        margin-right: auto;
      }
      .landingPage .sec1 h2 {
        font-size: 44px;
      }
      .landingPage .sec2 .price {
        font-size: 24px;
      }
      .landingPage .sec3Card h2 {
        font-size: 32px;
      }
      .landingPage .sec3Card .desc {
        font-size: 15px;
      }
      .landingPage .homeBtn {
        font-size: 15px;
      }
      .landingPage .sec3Card .imgDiv2 {
        margin-top: 20px;
      }
      .landingPage .sec4 h1 {
        font-size: 30px;
    }
    .landingPage .sec4 .descrip {
        font-size: 15px;
    }
    .landingPage .joinComunitySec h2 {
          font-size: 32px;
      }
      .landingPage .joinComunitySec {
        padding: 20px;
      }
      .landingPage .sec1 {
        display: flex;
        align-items: center;
      }
      .landingPage .sec1 h2 {
        margin-top: 0;
      }
      .landingPage .videoSec h1 {
        font-size: 35px;
      }
      .landingPage .videoSec .desc {
        font-size: 16px;
      }
      .landingPage .faqSec h1 {
        font-size: 35px;
      }
    }
    @media(max-width:340px){
      .landingPage .sec1 h2 {
        font-size: 40px;
      }

  }

  

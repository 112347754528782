@font-face {
  font-family: Proxima Nova;
  src: url("assets/font/Proxima-Nova-Font/Proxima\ Nova\ Font.otf");
}

.custom-table {
  width: 100%;
  /* Adjust the overall table width */
}

.custom-column {
  /* Adjust the width of specific columns */
  width: 200px;
  /* Example width */
}

:root {
  --header_height: 70px;
  --color1: #f5f5fa;
  --header: #30956b;
  --color2: #606060;
  --color3: #F9F9F9;
  --bs-success: #36B37E;
  --bs-green: #36B37E;
  --bs-red: #FF6565;
  --bs-danger: #FF6565;
}

.form-floating {
  position: relative;
}

.up,
.down,
.updown {
  font-size: 25px;
  cursor: pointer;
}

.icon-eye::before {
  top: 35%;
  right: 20px;
  cursor: pointer;
  content: attr(data-eye-icon);
  display: inline-block;
  font: normal normal normal 14px / 1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  position: absolute;
}



.text-success {
  color: #36B37E !important;
}

.btn-success {
  color: #fff;
  background-color: #36B37E !important;
  border-color: #36B37E !important;
}

.btn-check:focus+.btn-success,
.btn-success:focus,
.btn-check:hover+.btn-success,
.btn-success:hover {
  box-shadow: 0 0 0 0.25rem rgba(60, 153, 110, .5) !important;
}

.text-danger {
  color: #FF6565 !important;
}

body {
  font-family: Proxima Nova;
}

.loader {
  top: 0;
  left: 0;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10000000000000;
  background-color: rgba(0, 0, 0, 0.5);
}

.loader .spinner-border {
  font-size: 30px;
  width: 4rem;
  height: 4rem;
}

.jc-between {
  justify-content: space-between;
}

.jc-end {
  justify-content: end;
}

.ai-center {
  align-items: center;
}


.HeroSection .slider p {
  font-size: 1.5rem;
  letter-spacing: 0.3rem;
  margin-bottom: 0;
}

.HeroSection .slider h1 {
  font-size: 5rem;
  font-weight: 700;
  margin-bottom: 1rem;
}

.HeroSection .slider.slider_4 h1 {
  font-size: 7rem;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 0rem;
}

.HeroSection .slider h2 {
  font-size: 2.5rem;
  font-weight: 700;
  margin-bottom: 0rem;
}

.HeroSection .slider h4 {
  font-size: 2rem;
  letter-spacing: 0.5rem;
  margin-bottom: 0rem;
}

.HeroSection .slider .image3_1 {
  max-width: 50%;
}

.HeroSection .slider .btn {
  border-radius: 0;
}

.thisCarousel {
  border: 3px solid #F0F0F0;
  border-radius: 25px;
  /* padding: 10px 0px; */
}

.carousel-inner {
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
  background-color: #F0F0F0;
}

.slider-btns {
  display: flex;
  justify-content: center;
  gap: 20px;
  padding: 20px 10px;
  background-color: #fff;
  border-bottom-left-radius: 25px;
  border-bottom-right-radius: 25px;
}

.carousel-control-next,
.carousel-control-prev {
  position: unset;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  padding: 0;
  color: #fff;
  text-align: center;
  background: 0 0;
  border: 0;
  opacity: 0.9;
  transition: opacity .15s ease;
}

.coinmarketcap-currency-widget {
  background-color: #F5F5F5;
  font-family: Proxima Nova;
  font-weight: 600;
  color: #183B56;
}

.vi-banner {
  width: 100%;
  min-height: 500px;
  padding: 20px 0px;
  display: flex;
  align-items: center;
  background-position: center;
  background-repeat: no-repeat;
  background-blend-mode: overlay;
  background-image: radial-gradient(circle, rgba(0, 55, 92, 1) 50%, rgba(0, 26, 47, 1) 100%), url('./assets/NEW/postins-banner.png');
}

.vi-banner .container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* gap: 25px; */
}

.vi-banner .card {
  background-color: #002036;
  border-radius: 10px;
  overflow: hidden;
  border: none;
}

.vi-banner .card .topbar {
  height: 20px;
}

.topbar.success {
  background-color: #38F49F;
}

.topbar.danger {
  background-color: #FF6565;
}

.vi-banner .card .a13 {
  font-size: 16px;
  color: #F5F5F5;
  padding: 25px 15px 5px 15px;
  font-family: Proxima Nova;
}

.vi-banner .card .a14 {
  font-size: 22px;
  font-weight: bold;
  color: #F5F5F5;
  padding: 5px 15px 25px 15px;
  font-family: Proxima Nova;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.vi-banner .card .a14 .right {
  color: #38F49F;
  font-size: 18px;
}

.vi-banner .card .a14 .right span {
  color: #B7B7B7;
  font-size: 14px;
}

.vi-banner .card .a14 .badge {
  background-color: #36B37E;
  font-size: 14px;
  letter-spacing: 1px;
}



.vi {
  min-height: 300px;
}

.vi .card {
  background-color: transparent;
  border: none;
}

/* player */
.vi .card .video-react {
  min-height: 400px;
}

/* vimeo */
.vi .card div iframe {
  min-height: 200px;
  width: 100%;
}

/* youtube */
.vi .card iframe {
  min-height: 400px;
}

.vi-tab-sec {
  display: flex;
  flex-direction: row;
  justify-content: center;
  border: 1.851px solid #36B37E;
  max-width: 600px;
  margin: auto;
  margin-top: 100px;
  margin-bottom: 40px;
}


.vi-tab-sec .vi-tab {
  max-width: 200px;
  width: 100%;
  text-align: center;
  padding: 15px 10px;
  border: none;
  background-color: transparent;

  letter-spacing: 1px;
  color: #022B47;
  text-align: center;
  font-family: Proxima Nova;
  font-size: 16.922px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: uppercase;
}

.vi-tab-sec .vi-tab.active {
  background-color: #36B37E;
  color: white;

  text-align: center;
  font-family: Proxima Nova;
  font-size: 16.922px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: uppercase;
}

tbody,
td,
tfoot,
th,
thead,
tr {
  min-width: 100px;
}

.vi .table thead tr th {
  background-color: #022B47;

  padding: 25px 0px;
  color: #fff;
  text-align: center;
  font-family: Proxima Nova;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: uppercase;
}

.vi .table tbody {
  border: 4px solid #F0F0F0;
  border-top: none;
}

.vi .table tbody tr td {
  background-color: #FFF;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-right: 20px;

  color: #A1A1A1;
  text-align: center;
  font-family: Proxima Nova;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.vi .table tbody tr:hover td {
  background-color: #F0F0F0;
}

/* Dashboard */
.d-header {
  display: flex;
  justify-content: space-between;
  /* border: 1px solid red; */
  padding: 20px 30px;
  height: var(--header_height);
  /* background-color: var(--color1); */
  background: rgb(255, 255, 255);
  background: linear-gradient(90deg, rgba(255, 255, 255, 1) 20%, rgba(54, 179, 126, 1) 81%, rgba(48, 149, 107, 1) 100%);
}

.d {
  height: calc(100vh - var(--header_height));
  width: 100vw;
  display: flex;
  background-color: var(--color2);
}

.d .sidebar-main {
  height: calc(100vh - var(--header_height));
  max-width: 250px;
  width: 100%;
  background: rgb(255, 255, 255);
  background: linear-gradient(180deg, rgba(255, 255, 255, 1) 25%, rgba(54, 179, 126, 1) 75%, rgba(48, 149, 107, 1) 100%);
  padding: 15px 30px;
}

.d .sidebar-main h3 {
  text-decoration: underline;
}

.d .sidebar-main ul {
  list-style: none;
  padding-left: 0px;
}

.d .sidebar-main ul li {
  padding: 15px 0px;
}

.d .sidebar-main ul li a {
  text-decoration: none;
  color: var(--color2) !important;
}

.d .sidebar-main ul li a.active {
  font-weight: 600;
  /* text-decoration: underline; */
  border: 2px dashed;
  padding: 5px;
}

.d .dbody {
  background-color: var(--color3) !important;
  height: 100%;
  overflow-y: scroll;
  width: 100%;
  padding: 10px 20px;
}

.d .dbody .heading {
  text-decoration: underline;
}

.d .dbody .content {
  background-color: var(--color1);
  margin: 50px 0px;
  padding: 20px 20px;
  min-height: 300px;
}

.d .dbody .content button {
  margin-bottom: 20px;
}

.signal-table {
  background-color: #fff;
  box-shadow: 1px 0px 17px 4px rgba(83, 83, 83, 0.46);
  -webkit-box-shadow: 1px 0px 17px 4px rgba(83, 83, 83, 0.46);
  -moz-box-shadow: 1px 0px 17px 4px rgba(83, 83, 83, 0.46);
}

.signal-table .table {
  border-radius: 10px !important;
}



/* .sec4 .activeLine::before { */
.sec4 .activeLine {
  border-bottom: var(--textSecondColor) 5px solid;
}

/* ------------------ POST ----------------------- */

.payment-modal .modal-dialog {
  max-width: 350px;
  margin: auto;
}

.payment-modal .modal-content {
  box-shadow: rgba(0, 0, 0, 0.09) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
}

.payment-qrcode {
  max-width: 250px;
  text-align: center;
  margin: auto;
}

/* body {
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: #f5f8fa;
} */

.tweet {
  background-color: #F0F2F5;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  max-width: 1000px;
  width: 100%;
  padding: 20px;
  margin: 20px;
}

.tweet .head {
  display: flex;
  align-items: center;
}

.tweet .profile {
  display: flex;
  align-items: center;
  background: white;
  border-radius: 50%;
  border: 1px solid white;
}

.tweet .profile img {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  object-fit: cover;
}

.tweet .user-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.tweet .user-info p {
  color: #000000;
  font-family: Proxima Nova;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%;
  margin-top: 5px;
  margin-left: 5px;
  margin-bottom: 5px;
}

.tweet .user-info span {
  color: #978F8F;
  font-family: Proxima Nova;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%;
  margin-left: 5px;
}

.tweet-content {
  margin-top: 20px;
}

.tweet-content p {
  margin-left: 15px;
  font-family: Proxima Nova;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
}

.tweet-content .card img {
  max-width: 100%;
}

.tweet .foot {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;

  color: #000;
  font-family: Proxima Nova;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
}

.tweet .foot .left {
  display: flex;
  gap: 15px;
}


.react-select {
  /* background-color: #022B47; */
  /* padding: 0px !important; */
  width: 96%;
  height: 50px;
  margin: auto;
  /* margin-left: 15px !important;
  margin-right: 15px !important; */
  border: 1px solid #A1A1A1 !important;
}

.css-b62m3t-container {
  width: inherit;
}

.react-select:focus,
.css-1f43avz-a11yText-A11yText:focus,
.css-b62m3t-container:focus,
.css-15lsz6c-indicatorContainer:focus,
.css-1xc3v61-indicatorContainer:focus,
.css-1u9des2-indicatorSeparator:focus,
.css-1hb7zxy-IndicatorsContainer:focus,
.css-1xc3v61-indicatorContainer:focus {
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
}

.css-13cymwt-control {
  border-style: none !important;
  box-shadow: none !important;
  padding: 1px 5px !important;
}

.css-13cymwt-control:focus {
  border: 2px dotted red !important;
  outline: red !important;
}

.userPayments .modal-dialog {
  max-width: 900px;
}
.userPayments .headings {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 25px;
}
.userPayments .headings img {
  width: 20px;
}
.userPayments .signal-table {
  box-shadow: none !important;
}

/* Subscription Plans */
.planCard {
  max-width: 300px;
  text-align: center;
  margin-bottom: 20px;

  background: rgba(188, 188, 188, 0.05);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(2.5px);
  -webkit-backdrop-filter: blur(2.5px);
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.18);
}

.planCard .head {
  padding: 30px 0px;
  font-size: 40px;
  font-family: Proxima Nova;
}

.planCard .head.first,
.planCard .foot button.first {
  background-color: var(--header);
  color: white;
}

.planCard .head.second,
.planCard .foot button.second {
  background-color: var(--header);
  color: white;
}

.planCard .foot button {
  padding: 10px 20px;
  margin-bottom: 20px;
}

.planCard .foot button.first:hover {
  background-color: var(--header);
}

.planCard .foot button.second:hover {
  background-color: var(--header);
}

.planCard .body {
  padding: 80px 25px;
  font-size: 20px;
  font-family: Proxima Nova;
  color: var(--color2);
}

.PhoneInput input,
.PhoneInput input:focus {
  border: none;
  outline: none;
  color: #A1A1A1;
}



/* ------------------ PORTFOLIO ----------------------- */


.total-portfolio {
  font-weight: 600;
}

.in {
  color: #36B37E;
}

.out {
  color: #FF6565;
}

.addTx {
  text-align: end;
}

.portfolio-area {
  margin-top: 10px;
  margin-bottom: 10px;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.portfolio-area .card {
  width: 250px;
  height: fit-content;
  padding-bottom: 20px;
  /* -webkit-box-shadow: 4px 4px 12px -4px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 4px 4px 12px -4px rgba(0, 0, 0, 0.75);
  box-shadow: 4px 4px 12px -4px rgba(0, 0, 0, 0.75); */
  border: none;
}

.portfolio-area .card .topbar {
  margin-bottom: 20px;
}

.total-portfolio.card p,
.total-portfolio.card h1,
.portfolio-area .card p,
.portfolio-area .card h4,
.portfolio-area .card img {
  margin-left: 10px;
}

.portfolio-area .card .atp {
  color: #8F8F8F;
  font-size: 16.81px;
}

.portfolio-area .card h4 {
  font-weight: bold;
}

.portfolio-area .card .cry {
  display: flex;
  align-items: center;
  margin-bottom: 3px;
}

.portfolio-area .card .cry h4 {
  color: #022B47 !important;
  text-transform: uppercase;
}

.atx-modal-step1 button {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.atx-modal-step1 button .currency {
  display: flex;
}

.atx-modal-step1 button img {
  height: fit-content;
  margin-right: 5px;
}


.atx-modal-tabs {
  padding: 6px;
  background-color: #FBFBFB;
  border-radius: 5px;
  margin-bottom: 15px;
}

.atx-modal-tabs button {
  width: calc(100% / 2);
  color: #022B47;
  font-weight: 700;
  margin-top: 10px;
}

.atx-modal-tabs button.active {
  background-color: var(--bs-success);
}

.portfolio-modal .form-control {
  color: #022B47;
  font-weight: 500;
}

.portfolio-modal .form-label {
  color: #000;
  font-weight: 700;
}

.atx-modal.dropdown {
  margin-bottom: 15px;
}

.atx-modal.dropdown button {
  width: 100%;
  text-align: start;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.atx-modal.dropdown-menu {
  width: 100%;
}

.a233nb {
  padding: 15px 25px;
  background-color: rgba(211, 211, 211, 0.313);
  border-radius: 5px;
}

.a233nb p {
  color: #8F8F8F;
  font-size: 16px;
  margin-bottom: 0;
}

.a233nb h3 {
  color: #022B47;
  font-size: 36px;
  font-weight: 700;
  margin-bottom: 0;
}


.portfolio .card.list {
  border: 1.5px solid #DCDCDC;
  padding: 25px;
  min-height: 300px;
}

.portfolio .card.list .head {
  display: flex;
  justify-content: space-between;
}

.portfolio .card.list .head p {
  color: #022B47;
  font-weight: 700;
}

.bg-gray {
  background-color: #F0F2F5;
}

.portfolio .card.list .protfolio-item {
  cursor: pointer;
  display: flex;
  gap: 5px;
  align-items: center;
  padding: 10px 5px;
  border-radius: 6px;
}

.portfolio .card.list .protfolio-item .img {
  border-radius: 50%;
  width: 40px;
  overflow: hidden;
}

.portfolio .card.list .protfolio-item .content h5 {
  color: #022B47;
  font-weight: 700;
  font-size: 16px;
}

.portfolio .card.list .protfolio-item .content p {
  color: #A1A1A1;
  font-size: 12px;
}

.portfolio .card.list .add-protfolio button {
  color: #36B37E;
  font-weight: 700;
  font-size: 16px;
  margin-top: 20px;
}

.portfolio .card.list .add-protfolio button:hover {
  text-decoration: underline;
}

.portfolio .main {
  background: #F2F2F2;
  padding: 25px;
}

.portfolio .main .head {
  display: flex;
  justify-content: space-between;
}

.portfolio .main .head .left .info img {
  width: 30px;
  height: 30px;
  border-radius: 50%;
}

.portfolio .main .head .left .info span {
  font-size: 16px;
  font-weight: 700;
  color: #8F8F8F;
}

.portfolio .main .head .left .TotalPortfolioAmount h2 {
  font-weight: 700;
  font-size: 33px;
  color: #022B47;
}

.portfolio .main .head .left .TotalPortfolioAmount small {
  font-size: 16px;
  color: #36B37E;
  font-weight: 700;
}

.portfolio .main .head .right p {
  color: #022B47;
  font-style: 18px;
  font-weight: 700;
}

.portfolio .main .table>thead,
.as78 table>thead {
  vertical-align: bottom;
  background-color: #022B47;
  color: #fff;
}

.portfolio .main .table tbody,
.as78 .table tbody,
.portfolio .main .table td,
.as78 .table td,
.portfolio .main .table tfoot,
.as78 .table tfoot,
.portfolio .main .table th,
.as78 .table th,
.portfolio .main .table thead,
.as78 .table thead,
.portfolio .main .table tr,
.as78 .table tr {
  font-weight: 500;
}

.portfolio .main .table th,
.as78 .table th {
  padding: 20px 0px;
  padding-left: 20px;
}

.portfolio .main .table td,
.as78 .table td {
  padding: 20px 0px;
  padding-left: 20px;
  color: #022B47;
  font-size: 16px;
}


.as23 .dropdown-toggle::after {
  display: none;
}

.add-tx-lg {
  display: block;
}

.add-tx-sm {
  display: none;
}

.op-07 {
  opacity: 0.7;
}

.chainSwitchMain {
  font-size: 16px;
  background-color: #fff;
  padding: 5px;
}

.chainSwitchMain button {
  background-color: transparent;
  color: #000;
  padding: 7px 15px;
  border: none;
}

.chainSwitchMain button.active {
  background-color: var(--textSecondColor);
  color: #fff;
}

.dont-show-on-desktop {
  display: none;
}


.slider_content .content {
  /* width: 500px; */
}

/* Payment Timer */
#timer {
  padding: 30px 0px;
  display: flex;
  justify-content: center;
}

.timer-col span {
  display: flex;
  justify-content: center;
}

.timer-col .span_1 {
  font-size: 50px;
  line-height: 35px;
}
.timer-col .span_2 {
  font-size: small;
}

@media screen and (max-width: 1024px) {
  .HeroSection .slider h1 {
    font-size: 4rem;
  }
}

@media (max-width: 992px) {
  .add-protfolio {
    display: flex;
    gap: 10px;
  }

  .add-protfolio button {
    background-color: #36B37E;
    color: white;
  }

  .dont-show-on-desktop {
    display: block;
  }

  .dont-show-on-mobile {
    display: none;
  }

  .portfolio .cardListShow {
    display: none;
  }

  .portfolio-area {
    justify-content: space-between;
  }

  .portfolio-area .card {
    width: calc(100%/3.5);
  }
}


@media (max-width: 768px) {
  .HeroSection .slider h4 {
    font-size: 1.3rem;
  }

  .HeroSection .slider.slider_4 h1 {
    font-size: 5rem;
  }

  .HeroSection .slider h2 {
    font-size: 2rem;
  }

  .HeroSection .slider h1 {
    font-size: 3.2rem;
  }

  .portfolio-area .card {
    width: 100%;
  }

  .planCard {
    max-width: 250px;
    height: 350px;
  }

  .planCard .head {
    padding: 20px 0px;
    font-size: 30px;
  }

  .planCard .body {
    padding: 50px 10px;
    font-size: 16px;
  }

  .planCard .foot button {
    padding: 7px 10px;
    font-size: 13px;
  }
}

@media (max-width: 767px) {
  .HeroSection .row_reverse {
    flex-direction: column-reverse;
    height: auto !important;
  }

  .HeroSection .slider.slider_3 .content {
    width: 80%;
    margin: auto;
  }

  .HeroSection .side_image,
  .HeroSection .side_image_2 {
    width: 60%;
    margin: auto;
    margin-bottom: 2rem;
  }

  .HeroSection .right_col {
    display: flex !important;
    justify-content: center !important;
  }

  .HeroSection .slider .content {
    text-align: center;
  }
}

@media (max-width: 576px) {
  .HeroSection .slider .image3_1 {
    max-width: 70%;
  }

  .vi-tab-sec {
    margin-top: 60px;
    margin-bottom: 0px;
  }


  /* player */
  .vi .card .video-react {
    min-height: 200px;
  }

  /* vimeo */
  .vi .card div iframe {
    max-height: 200px;
    width: 100%;
  }

  /* youtube */
  .vi .card iframe {
    min-height: 200px;
  }

  .planCard {
    max-width: 180px;
    height: 250px;
  }

  .planCard .head {
    padding: 10px 0px;
    font-size: 20px;
  }

  .planCard .body {
    padding: 20px 10px;
    font-size: 13px;
  }

  .planCard .foot button {
    padding: 5px 10px;
    font-size: 12px;
  }

  .newsletter_section {
    padding: 50px !important;
  }
}

@media (max-width: 480px) {
  .portfolio .main .head .left .info span {
    display: block;
    font-size: 14px;
  }

  .add-tx-lg {
    display: none;
  }

  .add-tx-sm {
    display: block;
  }
}

.form-control[type=file]~label {
  padding-top: 0.625rem !important;
  padding-bottom: 0.825rem !important;
}

.form-floating>.form-select,
.form-floating>.form-control:focus,
.form-floating>.form-control:not(:placeholder-shown) {
  padding-top: 1.625rem !important;
  padding-bottom: 0.625rem !important;
}

.w-mc {
  width: max-content;
}

.newsletter input:focus,
.newsletter input:hover {
  border: none;
  outline: none;
}
:root {
  --textPrimaryColor: #183B56;
  --textSecondColor: #36b37e;
  --textSecondColorHover: #30956b;
  --textThirdColor: #022b47;
  --sliderHeight: 100vh;
}

.landingPage2 {
  background-color: #F5F5F5;
}

.landingPage2 #navbar {
  position: relative;
  z-index: 100;
}

.landingPage2 #navbar li a {
  color: var(--textPrimaryColor);
  font-weight: 600;
}

.landingPage2 #navbar .getIt {
  background-color: var(--textSecondColor);
  color: white;
  padding: 10px 30px;
  text-decoration: none;
  cursor: pointer;
  font-weight: 600;
}

.landingPage2 #navbar .getIt:hover {
  background-color: var(--textSecondColorHover);
  transition: 0.3s;
}

.HeroSection {
  position: relative;
  /* background-image: url("../../assets/NEW/hero-section.png");
  background-attachment: fixed;
  background-position: center;
  background-position-y: 75px;
  background-size: cover;
  background-repeat: no-repeat; */
  height: var(--sliderHeight);
}

.carousel-inner {
  background-color: #F0F0F0 !important;
  border-radius: 0%;
}

.carousel-inner img {
  width: 100%;
  object-fit: inherit;
}


@media (max-width: 768px) {
  .carousel-inner img {
    object-fit: cover !important;
    width: auto;
  }
}

.HeroSection .col1 p {
  font-size: 50px;
  font-weight: 600;
  position: relative;
  z-index: 0;
}

.HeroSection .col1 .Cursor {
  display: none;
}

.HeroSection .col1 p span {
  font-size: 120px;
  display: block;
  line-height: 80px;
  color: #fff;
  text-align: left;
  margin-left: 0%;
  margin-bottom: 5px;
  position: relative;
  z-index: 0;
}

.HeroSection .col1 {
  display: flex;
  /* align-items: center; */
  flex-direction: column;
  justify-content: center;
  padding-left: 100px;
  height: 100%;
}

.HeroSection .col1 .hero1 {
  color: #38F49F;
  stroke: #000000;
  font-size: 35px;
}

.HeroSection .col1 .hero2 {
  margin-left: 10px;
  color: #fff;
  font-size: 35px;
}

.HeroSection .col1 .hero3 {
  display: block;
  color: #fff;
  font-size: 100px;
  text-align: center;
}

.HeroSection .col1 .hero4 {
  color: #fff;
  font-size: 35px;
}

.HeroSection .col1 .hero5 {
  color: #38F49F;
  font-size: 35px;
  margin-left: 10px;
}

.HeroSection .col1 button {
  background-color: var(--textSecondColor);
  color: white;
  font-weight: 600;
  padding: 12px 25px;
  border-radius: 0px;
  display: flex;
  align-self: flex-start;
  margin-left: 10px;
}

.green_button {
  background-color: var(--textSecondColor);
  color: white;
  font-weight: 600;
  padding: 12px 25px;
  border-radius: 0px;
  display: block;
}

.green_button.active {
  background-color: var(--textSecondColorHover);
  color: white;
}

.green_button:focus {
  border: none;
}

.sec2 {
  padding: 50px;
  background-color: #F5F5F5;
}

.sec2 .learnExpert {
  padding-top: 50px;
  padding-bottom: 50px;
}

.sec2 .learnExpert .head1 {
  text-align: center;
}

.sec2 .learnExpert .head1 span {
  font-weight: 700;
  color: var(--textPrimaryColor);
  font-size: 40px;
}

.learnExpert .head1 .green {
  color: var(--textSecondColor) !important;
  text-decoration: underline;
  margin-left: 10px;
}

.learnExpert .desc {
  text-align: center;
  color: var(--textPrimaryColor);
}

.cardsec {
  height: 100% !important;
}

.cardsec .card {
  background-color: #fff;
  height: 100% !important;
}

.cardsec .card .img-card {
  background-color: #F0F0F0;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 30px 60px;
  margin: 10px;
  height: 200px;
}

.sec2 .card2 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 25px 10px;
}

.sec2 .card2 img {
  max-width: 100px;
}

.sec2 .card2 h6 {
  font-weight: 700;
  text-transform: uppercase;
  margin-top: 15px;
  color: var(--textPrimaryColor);
}

.sec2 .card2 p {
  text-align: center;
  color: var(--textPrimaryColor);
}






.sec3 {
  background-image: url("../../assets/NEW/Banner123.png");
  background-attachment: fixed;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  padding: 0px 100px;
}

.sec3 .socialLinks {
  display: flex;
  align-items: center;
}

.sec3 .socialLinks a {
  width: 35px;
  height: 35px;
  text-align: center;
  color: #cc0000;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  /* background-color: #ffffff; */
  font-size: 17px;
  position: relative;
  border-radius: 50%;
}

.sec3 .socialLinks .facebook {
  background-color: #4267b2;
  color: #ffffff;
}

.sec3 .socialLinks .facebook:hover {
  background-color: #ffffff;
  color: #4267b2;
}

.sec3 .socialLinks .twitter {
  background-color: #1da1f2;
  color: #ffffff;
}

.sec3 .socialLinks .twitter:hover {
  background-color: #ffffff;
  color: #1da1f2;
}

.sec3 .socialLinks .insta {
  background-color: #e1306c;
  color: #ffffff;
}

.sec3 .socialLinks .insta:hover {
  background-color: #ffffff;
  color: #e1306c;
}



.sec3 .sec3img img {
  max-height: 100vh;
}

.sec3 .content {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  justify-content: center;
  height: 100%;
}

.sec3 .content h1 {
  color: #fff;
  font-size: 50px;
  font-weight: 700;
  margin-bottom: 30px;
}

.sec3 .content p {
  color: #fff;
}


.sec4 {
  padding: 100px 200px;
}

.sec4 h1 {
  color: var(--textPrimaryColor);
  font-size: 50px;
  font-weight: 700;
  text-transform: uppercase;
  text-align: center;
}

.sec4 .i88 {
  display: block;
  color: var(--textSecondColor);
  font-size: 50px;
  font-weight: 700;
  text-transform: uppercase;
  text-align: center;
}

.sec4 p {
  color: var(--textPrimaryColor);
  text-align: center;
}

.sec5 {
  padding: 70px 0;
}

.sec5 .swiper-button-next:after,
.swiper-rtl .swiper-button-prev:after,
.sec5 .swiper-button-prev:after,
.swiper-rtl .swiper-button-next:after {
  display: none;
}

.sec5 .videoImgCard {
  width: 100%;
}

.sec5 .videoImgCard .imgDiv img {
  width: 100%;
  height: auto;
}

.sec5 .swiper {
  z-index: 0;
}

.sec5 .videoImgCard .title {
  padding: 20px 12px;
  font-size: 18px;
  font-weight: bold;
  min-height: 78px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.sec5 .videoImgCard .imgDiv {
  height: 420px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  overflow: hidden;
}

.sec5 h1 {
  color: #050C26;
  font-size: 50px;
  font-weight: 700;
  text-transform: uppercase;
  text-align: center;
}

.sec5 .autoplay-progress {
  display: none;
}

.sec5 p {
  color: #8A8A8A;
  text-align: center;
}





.slidecard {
  padding: 15px;
  background-color: white;
  display: flex;
  align-items: baseline;
  flex-direction: column;
}

.slidecard .content {
  color: #ACACAC;
}

.slidecard .user {
  display: flex;
  align-items: center;
  width: 100%;
  margin: 20px 10px;
}

.slidecard .user .img {
  margin-right: 10px;
}

.slidecard .user .data .h {
  display: block;
  color: #353535;
  font-weight: 600;
  font-size: 16.22;
}

.slidecard .user .data .p {
  display: block;
  color: #8E8E8E;
  font-size: 12.4;
}



.sec6 {
  padding: 100px 150px;
  background-color: #183B56;
}

.sec6 .main h1 {
  color: #fff;
  text-align: center;
  margin-bottom: 25px;
}

.sec6 .main .join {
  background-color: #fff;
  max-width: 500px;
  width: 100% !important;
  padding: 5px;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  margin: auto
}

.sec6 .main .join input {
  width: 100%;
  border: none;
}

.sec6 .main .join button {
  width: 250px;
}


.sec7 {
  padding: 100px 200px;
}

.sec7 h1 {
  color: var(--textPrimaryColor);
  font-size: 50px;
  font-weight: 700;
  text-transform: uppercase;
  text-align: center;
}


.coin-marquee-item-name,
.coin-marquee-item-price,
.coin-marquee-item-changes,
.coin-marquee-item-symbol,
.coin-marquee-header-signature div,
.coin-marquee-header-signature svg {
  font-size: medium !important;
}

.coin-marquee-item__icon {
  min-width: 20px;
  max-width: 30px;
  height: auto;
}

.coin-marquee-header-signature svg {
  width: 100%;
  height: 20px;
}


.footer {
  padding: 100px 0px 0px 0px;
}

.footer .f1 img {
  margin-bottom: 25px;
}

.footer .f1 p {
  color: #606060;
  word-spacing: 5px;
  max-width: 95%;
}

.footer h5 {
  color: #00052E;
}

.footer a {
  color: #606060;
  text-decoration: none;
  display: block;
  margin-bottom: 10px;
}


.footer .f12 {
  margin-top: 50px;
  border-top: 2px solid #B7B7B7;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 75px;
}

.footer .f12 span {
  color: #606060;
  text-align: center;
}

.c3421 {
  height: 150px;
}

@media (max-width: 1250px) {
  .HeroSection .col1 p {
    font-size: 46px;
  }

  .HeroSection .col1 p span {
    font-size: 100px;
  }
}

@media (max-width: 1200px) {
  .HeroSection .col1 p {
    font-size: 40px;
  }

  .HeroSection .col1 p span {
    font-size: 90px;
  }

  .HeroSection .col1 {
    padding-left: 50px;
  }
}

@media (max-width: 1024px) {
  .landingPage2 .nav-link {
    font-size: 14px;
  }

  .landingPage2 #navbar .getIt {
    padding: 10px 20px;
  }

  .navbar-toggler:focus {
    box-shadow: none;
  }

  .custom-toggler.navbar-toggler {
    border-color: #36b37e;
    border-width: 3px;
  }

  .custom-toggler .navbar-toggler-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(54, 179, 126, 7)' stroke-width='3' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
  }

  .HeroSection .col1 p {
    font-size: 34px;
  }

  .HeroSection .col1 p span {
    font-size: 80px;
  }

  .sec3 .sec3img {
    height: 100%;
    display: flex;
    align-items: flex-end;
  }

  .sec3 .sec3img img {
    max-width: 100%;
    height: fit-content;
  }

  .sec3 .content h1 {
    font-size: 40px;
    margin-bottom: 20px;
  }

  .sec3 .content p {
    height: 350px;
    overflow-y: auto;
  }

  .sec4 h1,
  .sec4 .i88 {
    font-size: 40px;
  }

  .sec5 h1 {
    font-size: 40px;
  }

  .footer a {
    word-break: break-word;
  }
}


@media(max-width: 768px) {
  .HeroSection .col1 {
    padding-left: 20px;
    height: calc(100vh - 100px);
    align-items: flex-start;
  }

  .HeroSection .col1 p {
    font-size: 30px;
  }

  .HeroSection .col1 p span {
    font-size: 80px;
  }

  .HeroSection .col1 button {
    margin-left: 3px;
  }

  .HeroSection .col1 p span {
    margin-left: -4px;
  }

  .cardsec {
    max-width: 400px;
    margin: auto;
  }

  .cardsec .card .img-card {
    padding: 15px 20px;
  }

  .card .card-body {
    padding: 0.5rem 0.5rem;
  }

  .c3421 {
    height: 100px;
    font-size: 13px;
  }

  .green_button {
    padding: 10px 10px;
  }

  .sec3 .content {
    padding: 20px 0px;
  }

  .sec3 .content h1 {
    text-align: center;
  }

  .sec3 .content p {
    height: unset;
    font-size: 13px;
    text-align: center;
  }

  .sec3 {
    padding: 0px 30px;
  }

  .sec3 .sec3img {
    justify-content: center;
  }

  .sec3 .sec3img img {
    max-height: 400px;
  }

  .sec4 {
    padding: 100px 50px;
  }

  .sec5 {
    padding: 100px 0;
  }

  .sec6 {
    padding: 100px 50px;
  }

  .sec7 {
    padding: 0px 30px;
  }

  .sec7 div div {
    margin-bottom: 10px;
  }

  .coin-marquee-item-name,
  .coin-marquee-item-price,
  .coin-marquee-item-changes,
  .coin-marquee-item-symbol {
    font-size: small !important;
  }

  .coin-marquee-header-signature div,
  .coin-marquee-header-signature svg {
    font-size: 10px !important;
  }

  .coin-marquee-item__icon {
    min-width: 10px;
    max-width: 15px;
    height: auto;
  }

  .coin-marquee-header-signature svg {
    width: auto;
    height: 12px;
  }



  .footer .container {
    max-width: unset;
  }
}


@media (max-width: 426px) {
  .HeroSection .col1 p {
    font-size: 30px;
  }

  .HeroSection .col1 p span {
    font-size: 75px;
  }

  .sec2 {
    padding: 20px;
  }

  .sec2 .learnExpert .head1 span {
    font-size: 30px;
  }

  .sec4 {
    padding: 75px 20px;
  }

  .sec4 h1,
  .sec4 span {
    font-size: 30px;
    /* padding-left: 50px;
    padding-right: 50px; */
  }

  .sec5 {
    padding: 100px 0;
  }

  .sec5 h1 {
    font-size: 30px;
  }

  .sec6 {
    padding: 75px 20px;
  }

  .c3421 {
    height: 110px;
  }
}

@media (max-width: 380px) {
  .HeroSection .col1 p {
    font-size: 25px;
  }

  .HeroSection .col1 p span {
    font-size: 65px;
  }
}
:root {
  --textPrimaryColor: #183B56;
  --textSecondColor: #36b37e;
  --textThirdColor: #022b47;
}

/* .login {
  background-color: #F5F5F5;
} */


.login #navbar {
  position: relative;
  z-index: 100;
}

.login #navbar li a {
  color: var(--textPrimaryColor);
}

.login #navbar .getIt {
  background-color: var(--textSecondColor);
  color: white;
  padding: 10px 30px;
  text-decoration: none;
  cursor: pointer;
}

.green_button {
  background-color: var(--textSecondColor);
  color: white;
  font-weight: 600;
  padding: 12px 25px;
  border-radius: 0px;
  display: block;
}

.login .green_button {
  width: 100%;
  background-color: var(--textSecondColor);
  color: white;
  font-weight: 600;
  padding: 12px 25px;
  border-radius: 8px;
  display: block;
}

.l_sec2 {
  padding: 50px;
  background-color: #F5F5F5;
}

.l_sec2 .CAP {
  padding-top: 50px;
  padding-bottom: 50px;
}

.l_sec2 .CAP .head1 {
  text-align: center;
}

.l_sec2 .CAP .head1 span {
  color: var(--textPrimaryColor);
  font-size: 40px;
  display: block;
  text-transform: uppercase;
  font-weight: 700;
}

.l_sec2 .cardsec {
  height: 100% !important;
}

.l_sec2 .cardsec .card {
  background-color: #fff;
  height: 100% !important;
}

.l_sec2 .cardsec .card .img-card {
  background-color: #F0F0F0;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 30px 60px;
  margin: 10px;
}

.l_sec2 .cardsec .c1 .card-body .card-title {
  color: var(--textSecondColor);
  font-size: 14px;
  font-weight: 600;
}

.l_sec2 .cardsec .c1 .card-body .card-title2 {
  color: var(--textPrimaryColor);
  font-size: 20px;
  font-weight: 700;
}

.l_sec2 .cardsec .c1 .card-body .aces {
  color: var(--textSecondColor);
  font-size: 14px;
  text-align: center;
  margin-top: 20px;
}

.qw123 .login {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 30px 50px;
  /* margin: 20px 30px; */
  max-width: 600px;
  height: 100%;
}

.qw123 .login .form-control {
  padding: 10px 20px;
  border-radius: 8px;
  border: 1px solid #A1A1A1;
  color: #A1A1A1;
}

.qw123 .login .col-form-label {
  color: #fff;
}

.qw123 .login .wq24 {
  color: #A1A1A1;
}

.qw123 .login .wq24 a {
  margin-left: 10px;
  color: #A1A1A1;
}

.qw123 .login .wq24 a:hover {
  font-weight: 700;
}

.qw123 .login .social {
  border: 1px solid var(--textPrimaryColor);
  color: var(--textPrimaryColor);
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.qw123 .login .social button:hover {
  font-weight: 700;
  cursor: pointer;
}

.qw123 .login .social button {
  background-color: transparent;
  border: none;
  width: 100%;
  padding: 10px 15px;
}

.AuthSlider .swiper-slide {
  width: 100% !important;
}

.authslidecard {
  width: 100% !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.authslidecard .img {
  background-color: #F0F0F0;
  padding: 50px;
  /* height: 500px;
width: 700px; */
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
  margin-bottom: 50px;
}


.authslidecard .data h4 {
  color: var(--textSecondColor);
  font-weight: 700;
}

.authslidecard .data h1 {
  color: var(--textPrimaryColor);
  font-weight: 700;
}

.qw123 {
  /* background-image: url("../../assets/NEW/Banner123.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover; */
  /* background-image: linear-gradient(#014126, #299972); */
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 85vh;
}

@media (max-width: 992px) {
  .login {
    margin: 0px auto !important;
  }
}

.authCard.login {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 30px 50px;
  max-width: 500px;
  margin: 20px 30px;
}

.authCard.login .col-form-label {
  color: #fff;
}

@media (max-width: 768px) {
  .l_sec2 .cardsec .card .img-card {
    padding: 15px 20px;
  }

  .l_sec2 .cardsec .c1 .card-body .card-title2 {
    font-size: 18px;
  }

  .l_sec2 .cardsec {
    max-width: 300px;
    margin-bottom: 15px;
    height: unset !important;
  }

  .cardsec {
    height: unset !important;
  }

  .authslidecard {
    display: none;
  }
}

@media(max-width: 576px) {
  .login {
    margin: 0px auto;
  }
  .qw123 .login {
    padding: 30px 30px;
  }
}

@media(max-width: 426px) {
  .l_sec2 {
    padding: 40px 20px;
  }

  .l_sec2 .CAP .head1 span {
    font-size: 30px;
  }

  .authCard.login {
    padding: 10px 20px;
    margin: 10px;
  }

  .login {
    padding: 20px 10px;
  }
}